<template>
    <div>
        <div class="number-form">
            <h3>Войти</h3>

            <input type="text" v-model="form.tel" class="number-form_input" placeholder="Номер телефона" v-mask="'+7 999-999-99-99'">
            <input v-if="form.step === 2" type="text" v-model="form.code" class="number-form_input" placeholder="Код из СМС">

            <div class="number-form_button">
                <div class="loading" v-if="form.loading"></div>
                <button v-else-if="form.step === 1" class="button __black" @click="sendTel">
                    <span>Получить код</span>
                </button>
                <div v-else-if="form.step === 2">
                    <button class="button __black" @click="sendCode">
                        <span>Войти</span>
                    </button>
                    <div class="number-form_error" v-if="form.error">Ошибка</div>
                    <div class="number-form_error" v-if="form.status === 'not_send_wait_prev'">Повторно можно отправить код через 1 минуту</div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            form: {
                step: 1,
                tel: '',
                code: '',
                loading: false,
                error: false,
                status: ''
            }
        }
    },
    methods: {
        async sendTel() {
            this.form.loading = true;
            let tel = this.form.tel.replaceAll(' ', '').replaceAll('-', '')

            let { data } = await this.axios.post('/games/t2_send_code', { msisdn: tel });

            this.form.step = 2;
            this.form.loading = false;

            this.form.status = data.status;
        },
        async sendCode() {
            this.form.loading = true;

            let tel = this.form.tel.replaceAll(' ', '').replaceAll('-', '')
            
            let {data} = await this.axios.post('/games/t2_confirm_code', { msisdn: tel, code: this.form.code });

            if (data.status === 'ok') {
                this.$router.push({name:'home'})
            } else {
                this.form.error = true;
            }

            this.form.loading = false;
        },
    }
}
</script>


<style scoped>

</style>
