<template>
	<div>
		<header class="header">
			<div class="wrapper">
				<div class="header_row">
					<router-link :to="{name: 'home'}" class="header_name">
						Росконтент
					</router-link>
					
					<div class="header_right">
						<div class="header_ico" @click.prevent="showMenu = true">
							<i></i><i></i><i></i>
							<span>Меню</span>
						</div>
					</div>
					
					<div class="header_menu" v-if="showMenu">
						<div class="header_menu-top">
							<div class="header_menu-prev" @click="changeMenu(prevMenu)" v-if="prevMenu.length"></div>
							<div class="header_menu-name"></div>
							<div class="header_menu-close" @click="showMenu = false"></div>
						</div>
						
						<div class="header_menu-data">
							<ul>
								<li v-show="menuItem.visible" :class="{ '__has-children' : menuItem.children }"
									class="header_menu-item" v-for="menuItem in currentMenu" :key="menuItem.url">
									<div class="a"
										 :class="{ 'router-link-active' : menuItem.isActive, 'show-children': showChildren }"
										 v-if="menuItem.children" :href="menuItem.url">
										<span @click="toggleMenu">{{ menuItem.title }}</span>
										
										<transition name="slide">
											<ul v-show="showChildren">
												<li class="header_menu-item __children" v-for="childrenItem in menuItem.children" :key="childrenItem.title">
													<router-link :to="childrenItem.url" class="a" exact>
														<span @click="showMenu = false">{{ childrenItem.title }}</span>
													</router-link>
												</li>
											</ul>
										</transition>
									</div>
									
									<router-link exact class="a" v-else :to="menuItem.url" :class="{ 'router-link-active' : menuItem.isActive }">
										<span @click="showMenu = false">{{ menuItem.title }}</span>
									</router-link>
								</li>
								<li class="header_menu-item" v-show="auth.msisdn">
									<a href="" class="a" @click.prevent="logout"><span>Выйти</span></a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</header>
	</div>
</template>

<script>
	import _ from 'underscore';
	import $ from 'jquery'

	export default {
		data() {
			return {
				menu: this.getDefaultData(),
				currentMenu: [],
				prevMenu: [],
				showMenu: false,
				showChildren: false,
				//auth: window.auth
			}
		},


		created() {
			this.currentMenu = this.menu;

			this.showChildren = this.$route.name === 'category';

			window.events.$on('page-changed', () => {
				this.resetData();
			});
		},

		mounted() {
			$(document).on('click', 'a, .match-block_row', ((e) => {
				if (!$(e.target).closest('.header_menu').length || $(e.target).closest('.match-block_row').length) {
					this.showMenu = false;
				}
			}));
		},

		methods: {
			changeMenu(menu) {
				this.prevMenu = this.currentMenu;
				this.currentMenu = menu;

				if (this.currentMenu === this.menu) {
					this.prevMenu = [];
				}
			},

			initActiveMenu() {
				let path = this.$route.path;
				let activeItem = null;

				_.each(this.menu, (item) => {
					_.each(item.children, (children) => {
						if (children.url === path) {
							item.active = true;
							activeItem = item;
						}
					});
				});

				return activeItem;
			},
			resetData() {
				this.menu = this.getDefaultData();

				this.currentMenu = this.menu;

				this.showChildren = this.$route.name === 'category';
			},
			getDefaultData() {
				let courses = [
					{title: 'Все курсы', url: {name: 'category'}}
				];
				
				this.categories.forEach((category) => {
					courses.push({
						title: category, url: {name: 'category', params: {id: category}}
					})
				});
				
				return [
					{
						title: 'Курсы',
						url: '#',
						isActive: this.$route.name === 'category',
						children: courses,
						visible: true
					},

					{
						title: 'Мои курсы',
						url: '/my',
						visible: true
					},
					
					{
						title: 'Промо',
						url: '/promo',
						visible: this.auth.have_promo
					},
					
					{
						title: 'Английский язык',
						url: '/english',
						visible: true
					},
				]
			},
			toggleMenu() {
				this.showChildren = !this.showChildren;
			},
			logout() {
				this.axios.get('/edu/logout').then(({data}) => {
					location.reload();
				});
			}
		},
	}
</script>
