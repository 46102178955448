<template>
	<div class="footer-main">
		<div class="footer-top" v-if="$route.name === 'subscribe'">
			<div class="wrapper">
				<div class="footer_text-2">
					
				</div>
			</div>
		</div>
		
		<footer class="footer">
			<div class="wrapper">
				<div class="footer_row">
					<div class="footer_text">Росконтент, {{ year() }}</div>
				</div>
			</div>
		</footer>
	</div>
</template>

<script>
	import moment from 'moment';

	export default {
		methods: {
			year() {
				return moment().format('Y')
			}
		},
		computed: {
			isEnglishPage() {
				let routeName = this.$route.name;

				
				if (['my-course-lessons', 'my-course-lesson', 'my-course-lesson-material'].includes(routeName)) {
					let course = this.$route.params.course ? parseInt(this.$route.params.course) : parseInt(this.$route.params.id);
					
					if ([99, 100, 101, 102].includes(course)) {
						return true;
					}
				}
				
				let routes = ['english', 'english-cards', 'english-my-cards', 'english-card'];

				return routes.includes(routeName);
			}
		}
	}
</script>
