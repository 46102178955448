<template>
    <div class="landing" @click="goToPage">
        <img src="/img/education.jpg" alt="">
        <img src="/img/education_mob.jpg" alt="">
        <br>
        <a href="/games/t2_subscribe" class="m-button">Учиться</a>
    </div>
</template>
<script>
export default {
    methods: {
        goToPage() {
            location.href = '/games/t2_subscribe';
        }
    }
}
</script>
<style scoped>
.landing {
    padding: 20px;
    text-align: center;
    position: fixed;
    top: 0;
    left:0;
    right: 0;
    bottom: 0;
    background: #fff;
    z-index: 99999;

    @media screen and (max-width: 1000px) {
        padding: 0;
    }

    .button {
        @media screen and (max-width: 1000px) {
            position: fixed;
            bottom: 100px;
            left: 50%;
            z-index: 100;
            transform: translateX(-50%);
        }
    }
}
.landing img {
    &:nth-child(1) {
        margin-bottom: 20px;
        width: 700px;
        max-width: 100%;

        @media screen and (max-width: 1000px) {
            display: none;
        }
    }
    &:nth-child(2) {
        display: none;

        @media screen and (max-width: 1000px) {
            display: block;
            width: 400px;
            max-width: 100%;
            margin: 0 auto;
        }
    }
}
.m-button {
    color: #fff;
    font-size: 20px;
    background: #ff5923;
    padding: 15px 30px;
    border-radius: 30px;
    position: fixed;
    bottom: 100px;
    left: 50%;
    z-index: 100;
    transform: translateX(-50%);
    text-decoration: none;

    @media screen and (max-width: 1000px) {
        display: none;
    }
}
</style>
