import Vue from 'vue'
import axios from 'axios'

let beforeEach = (to, from, next) => {
    let click_id = getQuery('click_id');

    if (click_id) {
        setCookie('click_id', click_id, 1);
    }
    
    if (to.name === 'auth' || to.name === 'landing' || to.name === 'landing2' || to.name === 'home') {
        next(); return;
    }

    axios.get('/games/t2_profile').then(({data}) => {
        if (!data.msisdn || !data.subscription) {
            location.href = '/games/t2_subscribe';
        } else {
            next(); return;
        }
    })
};

function setCookie(name,value,days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

function getQuery(param) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
}


export default beforeEach;
