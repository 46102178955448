<template>
    <div class="landing" @click="goToPage">
        <img src="/img/education2.jpg" alt="">
        <br>
    </div>
</template>
<script>
export default {
    methods: {
        goToPage() {
            location.href = '/games/t2_subscribe2';
        }
    }
}
</script>
<style scoped>
.landing {
    padding: 20px;
    text-align: center;
    position: fixed;
    top: 0;
    left:0;
    right: 0;
    bottom: 0;
    background: #fff;
    z-index: 99999;
    overflow: auto;

    @media screen and (max-width: 1000px) {
        padding: 0;
    }

    .button {
        @media screen and (max-width: 1000px) {
            position: fixed;
            bottom: 100px;
            left: 50%;
            z-index: 100;
            transform: translateX(-50%);
        }
    }
}
.m-button {
    color: #fff;
    font-size: 20px;
    background: #ff5923;
    padding: 15px 30px;
    border-radius: 30px;
    position: fixed;
    bottom: 100px;
    left: 50%;
    z-index: 100;
    transform: translateX(-50%);
    text-decoration: none;

    @media screen and (max-width: 1000px) {
        display: none;
    }
}
</style>
